<template>
  <div class="applicationPage">
    <HeadInfo :hide-right-items="true" />
    <div class="applicationMain">
      <div class="applicationHead">
        <!-- <div>
          <img
            src="../../assets/img/text-icon.png"
            width="226"
            alt="text icon"
          />
        </div> -->
        <div>
          <!-- <div class="applicationTitle">{{ clientName }}</div> -->
          <div class="applicationDescription" v-html="topContent"></div>
        </div>
      </div>
      <div v-if="topData.accessing === 2" v-show="pageList.length">
        <div
          v-for="(item, index) in pageList"
          :key="index"
          class="applicationBody"
        >
          <div class="cardTitle">{{ item.title }}</div>
          <div class="applicationDesc" v-html="item.description"></div>
          <div class="text-right">
            <button
              v-show="topData.accessing === 2"
              class="btn btnPrimary w-150"
              @click="apply(index)"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
    <FooterInfo />
  </div>
</template>

<script>
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import {
  getLoadingPage,
  getLoadingPageTitle,
  getClientName,
  rollValidationRequired,
  searchVoterRequired,
} from "@/api/application";
import { getWorkerPortalPage } from "@/api/app";

export default {
  data() {
    return {
      curIndex: 0,
      pageList: [],
      desc: "",
      clientName: "",
      topData: {
        accessing: 0,
        onDescription: "",
        offDescription: "",
      },
    };
  },
  components: {
    HeadInfo,
    FooterInfo,
  },
  computed: {
    topContent: function () {
      return this.topData.accessing === 2
        ? this.topData.onDescription
        : this.topData.offDescription;
    },
  },
  watch: {},
  mounted() {
    getClientName().then((data) => {
      this.clientName = data.data.clientName;
    });
    getLoadingPage().then((data) => {
      this.pageList = data.data;
      if (this.pageList.length) {
        this.curIndex = 0;
        this.desc = this.pageList[0].description;
        getLoadingPageTitle({
          workertypeid: this.pageList[this.curIndex].workerTypeId,
        }).then((data) => {
          this.topData = data.data;
        });
      } else {
        getLoadingPageTitle().then((data) => {
          this.topData = data.data;
        });
      }
    });
  },
  methods: {
    btnAction(item, index) {
      if(this.curIndex == index) {
        this.curIndex = -1;
      } else {
        this.curIndex = index;
      }
      this.desc = item.description;
      getLoadingPageTitle({ workertypeid: item.workerTypeId }).then((data) => {
        this.topData = data.data;
      });
    },
    apply(index) {
      const curItem = this.pageList[index];
      let query = {
        id: curItem.workerTypeId,
        pageName: curItem.pageName,
        title: curItem.title,
        needWorkerTypeDocument: curItem.needWorkerTypeDocument ? 1 : 0,
        workerTypeDocumentLabel: curItem.workerTypeDocumentLabel,
        workerTypeUploadId: curItem.workerTypeUploadId,
      };
      rollValidationRequired().then((rep) => {
        const isVoter = rep.data;
        if (isVoter == 2) {
          searchVoterRequired(curItem.workerTypeId).then((resp) => {
            if (resp.data == 2) {
              this.$router.push({
                name: "searchVoter",
                query: query,
              });
            } else {
              this.$router.push({
                name: "form",
                query: query,
              });
            }
          });
        } else {
          this.$router.push({
            name: "form",
            query: query,
          });
        }
      });
    },
  },
  async beforeRouteEnter(to, from, next) {
    let data = await getWorkerPortalPage(13);
    if (data.data.publicApplication == 2) {
      next({
        name: "urlAccessProhibit",
        query: {
          publicApplication: data.data.publicApplication,
        },
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.groupPart {
  cursor: pointer;
  padding: 10px;
  background: #eeeeee;
  border: 1px solid #ffffff;
  border-radius: 8px 8px 0px 0px;
}
</style>
